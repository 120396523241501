<template>
    <div id="order">
        <div class="subtitle">我的订单</div>
        <div class="empty" v-if="productList && productList.length === 0">
            <img src="https://res.peki.vip/20221111/5d08bfa425094be384509cb2c0f5e378.png" />
            <div class="desc">您还没有任何订单哦～</div>
            <a class="product-btn" href="/product">选课学习</a>
        </div>
        <template v-for="item in productList">
            <div class="mobile-order-item" :key="item.id">
                <div class="order-state">
                    <span class="order-time">订单创建时间：{{ item.addTime && item.addTime.substr(0, 11) }}</span>
                    <div class="state">
                        <span class="state1" v-if="item.status === 'UNPAID'">进行中</span>
                        <span v-else-if="item.status === 'PAID'">已完成</span>
                    </div>
                </div>
                <div class="product">
                    <img :src="item.packageInfo && item.packageInfo.bannerImageUrl" />
                    <div class="product-info">
                        <div class="product-name">{{ item.packageInfo ? item.packageInfo.title : '' }}</div>
                        <div class="teacher" v-if="item.packageInfo && item.packageInfo.courseTeacher">
                            {{ item.packageInfo.courseTeacher.name }}
                        </div>
                        <div class="teacher" v-else>-</div>
                    </div>
                </div>
                <div class="orderNo">订单号: {{ item.orderNo }}</div>
                <div class="price-opt">
                    <div class="price">价格: ¥{{ item.packageInfo && item.packageInfo.price / 100 }}</div>

                    <van-button
                        @click="toOrderVerifyPage(item.packageInfo)"
                        class="buy-btn"
                        v-if="item.status === 'UNPAID'"
                    >
                        立即支付
                    </van-button>
                    <van-button @click="toCoursePage" class="study-btn" v-else-if="item.status === 'PAID'">
                        前往学习
                    </van-button>
                    <div v-else>-</div>
                </div>
            </div>
            <div class="order-item" :key="item.id">
                <div class="title">
                    <div>
                        <span class="order-time">订单创建时间：{{ item.addTime && item.addTime.substr(0, 11) }}</span>
                        <span>订单号: {{ item.orderNo }}</span>
                    </div>
                    <div class="state">
                        <span class="state1" v-if="item.status === 'UNPAID'">进行中</span>
                        <span v-else-if="item.status === 'PAID'">已完成</span>
                    </div>
                </div>
                <div class="product">
                    <img :src="item.packageInfo && item.packageInfo.bannerImageUrl" />
                    <div class="product-info">
                        <div class="product-name">{{ item.packageInfo ? item.packageInfo.title : '' }}</div>
                        <div class="flex-container">
                            <div class="teacher" v-if="item.packageInfo && item.packageInfo.courseTeacher">
                                {{ item.packageInfo.courseTeacher.name }}
                            </div>
                            <div class="teacher" v-else>-</div>
                            <div class="price">价格: ¥{{ item.packageInfo && item.packageInfo.price / 100 }}</div>
                        </div>
                    </div>
                    <div class="opt">
                        <van-button
                            @click="toOrderVerifyPage(item.packageInfo)"
                            class="buy-btn"
                            v-if="item.status === 'UNPAID'"
                        >
                            立即支付
                        </van-button>
                        <van-button @click="toCoursePage" class="study-btn" v-else-if="item.status === 'PAID'">
                            前往学习
                        </van-button>
                        <div v-else>-</div>
                    </div>
                </div>
            </div>
        </template>
    </div>
</template>
<script>
import { userOrders } from '@/api/userApi.js'
import { coursePackageList } from '@/api/courseApi.js'

export default {
    name: 'order',
    data() {
        return {
            productList: null,
        }
    },
    mounted() {
        this.userOrdersApi()
    },
    methods: {
        toCoursePage() {
            this.$router.push('/course')
        },
        toOrderVerifyPage(item) {
            this.$router.push(`/orderVerify/${item.packageNo}`)
        },
        userOrdersApi() {
            this.$toast.loading({
                duration: 0,
                message: 'Loading',
            })
            userOrders().then((res) => {
                const list = []
                if (res.data) {
                    res.data.forEach((item) => {
                        list.push(item.productNo)
                    })
                    coursePackageList(list.join(',')).then((data) => {
                        res.data.forEach((item) => {
                            data.data.forEach((packageItem) => {
                                if (item.productNo === packageItem.packageNo) {
                                    item.packageInfo = packageItem
                                }
                            })
                        })
                        this.$toast.clear()
                        this.productList = res.data
                    })
                } else {
                    this.$toast.clear()
                }
            })
        },
    },
}
</script>

<style lang="scss" scoped>
#order {
    padding-bottom: 32px;
    .subtitle {
        width: 100%;
        height: 64px;
        line-height: 64px;
        font-size: 16px;
        padding-left: 32px;
        box-sizing: border-box;
        border-bottom: 1px solid #e6e6e6;
        color: #33312f;
        font-weight: 700;
    }
    .order-item {
        margin: 24px 32px 0;
        border: 1px solid rgba(245, 245, 245, 0.92);
        .title {
            background: rgba(245, 245, 245, 0.92);
            height: 44px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            font-size: 12px;
            color: #66625e;
            font-weight: 700;
            .order-time {
                padding: 0 32px 0 24px;
            }
            .state {
                font-size: 12px;
                margin-right: 20px;
                .state1 {
                    color: #fa2f19;
                }
                .state2 {
                    color: #99948d;
                }
            }
        }
        .product {
            display: flex;
            padding: 20px 0 20px 20px;
            > img {
                width: 164px;
                height: 92px;
                flex-shrink: 0;
                object-fit: cover;
            }
            .product-info {
                padding: 0 10px 0 28px;
                border-right: 1px solid rgba(230, 230, 230, 0.92);
                flex-grow: 1;
                .product-name {
                    font-weight: 700;
                    font-size: 16px;
                    color: #33312f;
                    line-height: 22px;
                }
                .flex-container {
                    margin-top: 12px;
                    display: flex;
                    font-size: 14px;
                    line-height: 14px;
                    justify-content: space-between;
                    .teacher {
                        color: #66625e;
                    }
                    .price {
                        font-weight: 700;
                        color: #fa2f19;
                    }
                }
            }
            .opt {
                width: 206px;
                flex-shrink: 0;
                display: flex;
                align-items: center;
                justify-content: center;
                .buy-btn {
                    height: 32px;
                    border-radius: 4px;
                    color: $theme-color;
                    border-color: $theme-color;
                }
                .study-btn {
                    background: $theme-color;
                    height: 32px;
                    border: none;
                    border-radius: 4px;
                }
            }
        }
    }

    .empty {
        border-radius: 8px;
        background: #ffffff;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 100px 0;
        img {
            background: none;
            width: 320px;
            height: 219px;
        }
        .desc {
            font-size: 16px;
            font-weight: 700;
            color: #66625e;
            margin: 28px 0;
        }
        a {
            width: 120px;
            height: 36px;
            background: $theme-color;
            border-radius: 4px;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 15px;
        }
    }
}

.mobile-order-item {
    display: none;
    background: #fff;
    margin: 16px 16px 0;
    padding: 12px;
    .order-state {
        font-size: 12px;
        color: #666666;
        line-height: 1.5em;
        display: flex;
        justify-content: space-between;
        .state {
            .state1 {
                color: #fa2f19;
            }
            .state2 {
                color: #99948d;
            }
        }
    }
    .product {
        display: flex;
        height: 56px;
        margin: 8px 0;
        > img {
            width: 100px;
            height: 100%;
            object-fit: cover;
            flex-shrink: 0;
        }
        .product-info {
            margin-left: 8px;
            flex-grow: 1;
            height: 100%;
            box-sizing: border-box;
            padding: 4px 0;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            font-size: 14px;
            .product-name {
                font-weight: 700;
                color: #33312f;
            }
            .teacher {
                color: #666666;
            }
        }
    }
    .orderNo {
        font-size: 12px;
        color: #999999;
        padding: 12px 0 16px;
        border-bottom: 1px solid rgba(230, 230, 230, 0.92);
    }
    .price-opt {
        padding-top: 12px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .price {
            color: #fa2f19;
            font-size: 16px;
            font-weight: 700;
        }
        .van-button {
            width: 80px;
            height: 30px;
            font-size: 12px;
        }
        .buy-btn {
            height: 32px;
            border-radius: 4px;
            color: $theme-color;
            border-color: $theme-color;
        }
        .study-btn {
            background: $theme-color;
            height: 32px;
            border: none;
            border-radius: 4px;
        }
    }
}

@media screen and (max-width: 720px) {
    #order {
        background: $theme-bg-color;
        .order-item {
            display: none;
        }
        .mobile-order-item {
            display: block;
        }
        .empty {
            padding: 40px 0;
            img {
                width: 280px;
                height: 191px;
            }
        }
    }
}
</style>
